import styled, { css } from "styled-components";

interface Props {
  name: string;
  color: string;
  href?: string;
}

export default function CompanyName({ name, href, color }: Props) {
  const linkId = `${name}-link`;

  const handleClick = () => {
    const link = document.getElementById(linkId);
    link?.click();
  };

  return (
    <>
      <StyledHeader color={color} onClick={handleClick} href={href}>
        {name}
      </StyledHeader>
      {/* eslint-disable-next-line */}
      {href && <a id={linkId} href={href} target="_blank"></a>}
    </>
  );
}
const StyledHeader = styled.h3<{ color: string; href?: string }>`
  text-decoration: underline;
  margin-bottom: -4px;
  transition: 0.3s;
  width: fit-content;

  ${(props) => css`
    text-decoration-color: ${props.color};
    ${props.href
      ? css`
          & a {
            text-decoration: none;
          }
          &:hover {
            cursor: pointer;
            color: ${props.color};
          }
        `
      : ""}
  `}
`;
