import BulletList from "./BulletList";
import { SectionHeader } from "./styled";

export default function Interests() {
  return (
    <div>
      <SectionHeader>Interests</SectionHeader>
      <BulletList
        items={[
          "Hiking",
          "Reading",
          "Bouldering",
          "Recently started scuba diving",
        ]}
      />
    </div>
  );
}
