import DateText from "../DateText";
import {
  SectionHeader,
  DescriptionContainer,
  ExperienceItem,
  List,
  PositionDescriptionText,
} from "../styled";
import CompanyName from "../CompanyName";
import Position from "../Position";

export default function Experiences() {
  const plandayTechLedResps = [
    "Part of a Scrum team of five developers, one QA, one Designer, and one Product Manager",
    "Responsible for the technical decisions, vision, and roadmap of the team",
    "Making sure that the team’s technical work is aligned with the standards (set by the architects)",
    "Closely collaborating with the Product Manager to plan and prepare the features in a shape where they are ready to be implemented",
    "Prioritizing critical bugs with the Product Manager and Support",
    "Manage cross-team dependencies",
    "Writing Frontend code",
    "Participating in the quarterly OKR setting workshops (maybe this is an overstatement, as we just started it and it is not really working)",
    "Conducting technical interviews of Frontend engineers",
    "Anything about product discovery?",
    "Support Engineering manager in the performance reviews (this could be part more of the cover letter)",
  ];

  const plandayFrontEnd = {
    responsibilities: [
      "Writing Frontend code",
      "Writing integration and unit tests",
      "Contributing to the shared UI and core app library",
      "Refining the features in close collaboration with the UX designer and Product Manager",
      "Active participation in the Frontend guild meetings",
    ],
    techStack: ["React with Typescript", "Micro frontends"],
  };

  const danfossFullstack = {
    responsibilities: [
      "Developing backend and web frontend of a cloud-based monitoring solution for district heating management.",
      "Working in an internationally distributed Scrum team (Denmark, Slovenia, Russia, Poland)",
    ],
    techStack: [
      "React",
      "Node.js",
      "Typescript",
      "MongoDB, InfluxDB, MySQL",
      "Docker",
    ],
  };

  return (
    <div>
      <SectionHeader>Work Experience</SectionHeader>
      <List>
        <ExperienceItem>
          <CompanyName
            name="Planday"
            color="#199bd7"
            href="https://www.planday.com/"
          />
          <DescriptionContainer>
            <DateText from="March, 2021" to="present" />
            <span>Copenhagen, Denmark</span>
            <span>
              Software company that is creating Saas product for scheduling
              work, creating payroll and HR management.
            </span>
          </DescriptionContainer>
          <Position
            name="Technical Lead"
            responsibilities={plandayTechLedResps}
            period={{ from: "April, 2022", to: "present" }}
          />
          <Position
            name="Senior Frontend Engineer"
            responsibilities={plandayFrontEnd.responsibilities}
            techStack={plandayFrontEnd.techStack}
            period={{ from: "March, 2021", to: "April, 2022" }}
          />
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName name="Personal Goal pursuit" color="#434BEA" />
          <DescriptionContainer>
            <DateText from="July, 2020" to="March, 2021" />
          </DescriptionContainer>
          <PositionDescriptionText>
            I was spending time learning and deepening my knowledge about
            programming and product development while trying to start my own
            business.
          </PositionDescriptionText>
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName
            name="Danfoss Heating"
            color="rgb(225, 0, 18)"
            href="https://www.danfoss.com/en/markets/district-energy"
          />
          <DescriptionContainer>
            <DateText from="September, 2017" to="July, 2020" />
            <span>Vejle, Denmark</span>
          </DescriptionContainer>
          <Position
            name="Fullstack Engineer"
            responsibilities={danfossFullstack.responsibilities}
            techStack={danfossFullstack.techStack}
          />
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName
            name="Danfoss Drives"
            color="rgb(225, 0, 18)"
            href="https://www.danfoss.com/en/about-danfoss/our-businesses/drives/"
          />
          <DescriptionContainer>
            <DateText from="September, 2016" to="July, 2017" />
            <span>Graasten, Denmark</span>
          </DescriptionContainer>
          <Position name="Intern - Master Thesis Project collaboration" />
        </ExperienceItem>
      </List>
    </div>
  );
}
