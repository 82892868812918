import BulletList from "./BulletList";
import { SectionHeader } from "./styled";

export default function Languages() {
  return (
    <div className="languages">
      <SectionHeader>Languages</SectionHeader>
      <BulletList
        items={["Hungarian - native", "English - fluent", "Russian - basic"]}
      />
    </div>
  );
}
