import styled from "styled-components";
import BulletList from "./BulletList";

interface Props {
  name: string;
  responsibilities?: string[] | string;
  techStack?: string[];
  period?: { from: string; to: string };
}

export default function Position({
  name,
  responsibilities,
  techStack,
  period,
}: Props) {
  return (
    <Container>
      <h4>{name}</h4>
      {period && <PeriodText>{`${period.from} - ${period.to}`}</PeriodText>}
      {typeof responsibilities === "object" ? (
        <BulletList items={responsibilities} />
      ) : (
        <ResponsibilityText>{responsibilities}</ResponsibilityText>
      )}
      {techStack && (
        <>
          <span>
            <b>Tech:</b>
          </span>
          <BulletList items={techStack} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem 0;
`;

const ResponsibilityText = styled.div`
  margin-bottom: 0.5rem;
`;

const PeriodText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;
