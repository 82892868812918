import DateText from "../DateText";
import {
  SectionHeader,
  DescriptionContainer,
  ExperienceItem,
  List,
  PositionDescriptionText,
} from "../styled";
import CompanyName from "../CompanyName";
import Position from "../Position";

export default function Experiences() {
  const plandayTechLedResps = [
    "Part of a Scrum team of five developers, one QA, one Designer, and one Product Manager",
    "Responsible for the technical decisions, vision, dependencies, and roadmap of the team",
    "Making sure that the team's technical work is aligned with the company engineering standards",
    "Conducting daily stand-ups and refinements",
    "Closely collaborating with the Product Manager to plan and prepare the feature and discovery work, prioritize backlog and manage dependencies ",
    "Prioritizing critical bugs with the Product Manager and Second-line Support",
    "Writing high-quality Frontend code",
    "Participating in the quarterly OKR workshops and making sure that the team's work is aligned with the set key results",
    "Conducting technical interviews",
    "Supporting the Engineering Manager with bi-weekly updates about the team's performance",
  ];

  const plandayFrontEnd = {
    responsibilities: [
      "Writing Frontend code",
      "Writing integration and unit tests",
      "Contributing to the shared UI and core app library",
      "Refining the features in close collaboration with the UX designer and Product Manager",
      "Active participation in the Frontend guild meetings",
    ],
    techStack: ["React with Typescript", "Micro frontends"],
  };

  const danfossFullstack = {
    responsibilities: [
      "Developing Backend and web Frontend of a cloud-based monitoring solution for district heating management.",
      "Working in an internationally distributed Scrum team (Denmark, Slovenia, Russia, Poland)",
    ],
    techStack: [
      "React",
      "Node.js",
      "Typescript",
      "MongoDB, InfluxDB, MySQL",
      "Docker",
    ],
  };

  return (
    <div>
      <SectionHeader>Work Experience</SectionHeader>
      <List>
        <ExperienceItem>
          <CompanyName
            name="Planday"
            color="#199bd7"
            href="https://www.planday.com/"
          />
          <DescriptionContainer>
            <DateText from="March, 2021" to="present" />
            <span>Copenhagen, Denmark</span>
            <span>
              Software company that is creating Saas product for scheduling
              work, creating payroll and HR management.
            </span>
          </DescriptionContainer>
          <Position
            name="Technical Lead"
            responsibilities={plandayTechLedResps}
            period={{ from: "April, 2022", to: "present" }}
          />
          <Position
            name="Senior Frontend Engineer"
            responsibilities={plandayFrontEnd.responsibilities}
            techStack={plandayFrontEnd.techStack}
            period={{ from: "March, 2021", to: "April, 2022" }}
          />
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName name="Personal Goal pursuit" color="#434BEA" />
          <DescriptionContainer>
            <DateText from="July, 2020" to="March, 2021" />
          </DescriptionContainer>
          <PositionDescriptionText>
            I was spending time learning and deepening my knowledge about
            programming and product development while trying to start my own
            business.
          </PositionDescriptionText>
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName
            name="Danfoss Heating"
            color="rgb(225, 0, 18)"
            href="https://www.danfoss.com/en/markets/district-energy"
          />
          <DescriptionContainer>
            <DateText from="September, 2017" to="July, 2020" />
            <span>Vejle, Denmark</span>
          </DescriptionContainer>
          <Position
            name="Fullstack Engineer"
            responsibilities={danfossFullstack.responsibilities}
            techStack={danfossFullstack.techStack}
          />
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName
            name="Danfoss Drives"
            color="rgb(225, 0, 18)"
            href="https://www.danfoss.com/en/about-danfoss/our-businesses/drives/"
          />
          <DescriptionContainer>
            <DateText from="September, 2016" to="July, 2017" />
            <span>Graasten, Denmark</span>
          </DescriptionContainer>
          <Position
            name="Intern - Master Thesis Project collaboration"
            responsibilities={
              "Project title: Machine Learning for Frequency Converters"
            }
          />
        </ExperienceItem>
      </List>
    </div>
  );
}
