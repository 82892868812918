import profile from "./profile.jpeg";
export default function Header() {
  return (
    <div className="header">
      {/* eslint-disable-next-line */}
      <img src={profile} alt="Profile picture" className="profileImg" />
      <div className="contact">
        <h3>Gergő Halmi</h3>
        {/* <span>Online CV: halmigergo.hu/bankingCircle</span> */}
        {/* eslint-disable-next-line */}
        <a href="https://www.linkedin.com/in/gergo-halmi" target="_blank">
          LinkedIn
        </a>
        <span>halmig@hey.com</span>
        <span>+45 29 21 83 89</span>
        <span>Copenhagen, Denmark</span>
      </div>
    </div>
  );
}
