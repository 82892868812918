import CompanyName from "./CompanyName";
import DateText from "./DateText";
import Position from "./Position";
import {
  DescriptionContainer,
  ExperienceItem,
  List,
  SectionHeader,
} from "./styled";

export default function Education() {
  const mscResps = [
    "Specialized in Embedded Control Systems",
    "Distributed Embedded Systems",
    "Real Time Systems",
    "Programming in C, C++, VHDL",
  ];

  const bscResps = [
    "Specialized in Instrumentation and Automation",
    "Embedded Software Development",
    "Programming in C, Perl",
  ];

  return (
    <div>
      <SectionHeader>Education</SectionHeader>
      <List>
        <ExperienceItem>
          <CompanyName color="black" name="University of Southern Denmark" />
          <DescriptionContainer>
            <DateText from="September, 2015" to="July, 2017" />
            <span>Sønderborg, Denmark</span>
          </DescriptionContainer>
          <Position name="MSc in Mechatronics" responsibilities={mscResps} />
        </ExperienceItem>
        <ExperienceItem>
          <CompanyName color="darkBlue" name="University of Óbuda" />
          <DescriptionContainer>
            <DateText from="September, 2011" to="July, 2015" />
            <span>Budapest, Hungary</span>
          </DescriptionContainer>
          <Position
            name="BSc in Electrical Engineering"
            responsibilities={bscResps}
          />
        </ExperienceItem>
      </List>
    </div>
  );
}
