import styled from "styled-components";

export const SectionHeader = styled.h3`
  text-align: center;
  margin: 1rem 0 0 0;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  font-size: 1.125rem;
`;

export const ExperienceItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const DescriptionContainer = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

export const PositionDescriptionText = styled.span`
  padding: 0.5rem 0;
`;
