import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import CoverLetter from "./components/CoverLetter";
import NoMatch from "./components/NoMatch";
import Resume from "./components/Resume";
import BankingCircleResume from "./components/BankingCircle/Resume";
import BankingCircleCoverLetter from "./components/BankingCircle/CoverLetter";

// TODO finish up education
// TODO finish  up the hobby part
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/37signals"
            element={
              <>
                <h1>Engineering Manager Application</h1>
                <CoverLetter />
                <Resume />
              </>
            }
          />
          <Route
            path="/bankingCircle"
            element={
              <>
                <h1>Engineering Team Lead Application</h1>
                <BankingCircleCoverLetter />
                <BankingCircleResume />
              </>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
