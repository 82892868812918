import styled from "styled-components";

interface Props {
  items: string[];
}

export default function BulletList({ items }: Props) {
  return (
    <List>
      {items.map((item, index) => (
        <ListItem key={index}>{item}</ListItem>
      ))}
    </List>
  );
}

const List = styled.ul`
  list-style: circle;
  margin: 0;
`;

const ListItem = styled.li`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`;
