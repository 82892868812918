interface Props {
  from: string;
  to: string;
}

export default function DateText({ from, to }: Props) {
  return (
    <span>
      <b>{`${from} - ${to}`}</b>
    </span>
  );
}
