import styled from "styled-components";

export default function Divider() {
  return (
    <Container>
      <svg
        width="235"
        height="4"
        viewBox="0 0 235 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="235" height="4" rx="2" fill="#243a52" />
      </svg>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
