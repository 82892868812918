export default function CoverLetter() {
  const paragraphs = [
    "Dear Hiring Manager,",
    "I am writing to express my interest in the Engineering Team Lead position.",
    "Responsibilities of my current Technical Lead role are overlapping with the Engineering Team Lead position with the only exception that I don’t have direct reports, but I believe that my experiences and skills that I have gained are good matches for the position. My goal has always been to become a people manager and I am consciously working on my leadership and people skills by reading books and picking up responsibilities that provide the relevant experiences. Also, as part of the personal growth work with my Engineering Manager, we were preparing me to take on the manager role.",
    "I like to see when people challenge themselves and grow, and I like helping them to go on that road. I always look for solutions and possibilities instead of being pulled down by the negative aspect of the circumstances, and if needed I am actively helping people notice that they can be reactive and change their approach to problems. I also see myself as a good guardian of high standards and not letting bad habits fall through which then later becomes the new normal and causes a slow decrease in the quality of work.",
    "What also motivates me in the described Engineering Team Lead role, that I could be part of the team's everyday work, and influence the roadmap by collaborating with the Product Owner.",
    "I am particularly excited about the profile of Banking Circle because in the last year, I started to read a lot about cryptocurrencies which lead me to read a lot about monetary systems. As reading about money has become almost an everyday activity for me, it would be very rewarding if I continued my career at Banking Circle, where picking up this kind of knowledge could actually be beneficial to my work.",
    "I hope we will have a chance to have a chat to discover the possibility of working together in the future.",
    "Best Regards,",
    "Gergő Halmi",
  ];

  return (
    <section className="coverLetter">
      <h2>Cover letter</h2>
      {paragraphs.map((p, i) => (
        <p key={i}> {p}</p>
      ))}
    </section>
  );
}
