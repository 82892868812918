import styled from "styled-components";

export default function NoMatch() {
  return <Text>404</Text>;
}

const Text = styled.div`
  text-align: center;
  font-size: 10rem;
  margin-top: 10rem;
`;
