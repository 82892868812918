import Education from "./components/Education";
import Experiences from "./components/Experiences";
import Header from "./components/Header";
import Interests from "./components/Interets";
import Divider from "./components/Divider";

export default function Resume() {
  return (
    <section className="cv">
      <h2>Resumé</h2>
      <Header />
      <Divider />
      <Experiences />
      <Divider />
      <Education />
      <Divider />
      <Interests />
    </section>
  );
}
